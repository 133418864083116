import React, { Component } from "react";
import { saveEnquiry, getCountryList,getLeadsTypes } from "../../redux/actions/dataAction";
import { connect } from "react-redux";
import isEmpty from "../../redux/validation/is-Empty";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      comment: "",
      phone: "",
      country_id: "",
      courseId: "",
      ctryList: null,
      loader: false,
      type: "",
      leadTypes: null,
      error: {
        nameErr: "",
        emailErr: "",
        phoneErr: "",
        ctryErr: "",
        typeErr: null,
      },
    };
  }
  componentDidMount() {
    this.props.getCountryList();
    this.props.getLeadsTypes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.saveEq !== this.props.saveEq) {
      if (nextProps.saveEq) {
        if (!isEmpty(nextProps.saveEq)) {
          this.setState({
            loader: false,
            name: "",
            email: "",
            subject: "",
            comment: "",
            phone: "",
            country_id: "",
            courseId: "",
            error: {
              nameErr: "",
              emailErr: "",
              subjectErr: "",
              phoneErr: "",
            },
          });
        }
      }
    }
    if (nextProps.crty_lst !== this.props.crty_lst) {
      if (nextProps.crty_lst) {
        this.setState({
          ctryList: nextProps.crty_lst,
        });
      }
    }
    if (nextProps.ldTypes !== this.props.ldTypes) {
      if (nextProps.ldTypes) {
        this.setState({
          leadTypes: nextProps.ldTypes,
        });
      }
    }
  }
  formValidator() {
    let isValid = false;
    let nm = false,
      em = false,
      psw = false,
      phno = false,
      tp = false,
      sub = false;
    let rEmailErr =
      this.state.email.length <= 0
        ? "Email is required!"
        : /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            this.state.email
          )
        ? (em = true)
        : "Email id is not valid!";
        let tpErr = this.state.type.length <= 0 ? "Type is required!" : (tp = true);
    // let rPasswordErr =
    //   this.state.name.length <= 0 ? "Name is required!" : (psw = true);
    // let phnoErr =
    //   this.state.phone.length <= 0 ? "Phone is required!" : (phno = true);
    // let ctryErr =
    //   this.state.country_id.length <= 0 ? "Country is required!" : (sub = true);

    this.setState({
      error: {
        emailErr: rEmailErr,
        // nameErr: rPasswordErr,
        // phoneErr: phnoErr,
        // ctryErr: ctryErr,
        typeErr: tpErr,
      },
    });
    if (em && tp) {
      isValid = true;
    }
    return isValid;
  }
  submitForm(e) {
    e.preventDefault();
    // toast.success("Wow so easy!")
    if (this.formValidator()) {
      this.setState({
        loader: true,
      });
      const data = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        comment: this.state.comment,
        phone: this.state.phone,
        course_id: this.state.courseId,
        country_id: this.state.country_id,
        lead_type_id: this.state.type,
        user_id: "",
      };
      this.props.saveEnquiry(data);
    }
  }
  render() {
    const { error } = this.state;
    return (
      <div className="ft_main_wrapper" id="ft_main_wrapper">
        {!this.props.hideFooter && (
          <div className="container">
            <div className="position-relative">
              <div className="contact_sm_wrapper ">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-lg-4">
                    <div className="contact_sm_container">
                      <div>
                        <p className="sm_heading">Contact us</p>
                        <h1 className="main_heading">You need any Help?</h1>
                      </div>
                      <div className="img_wrapper">
                        <img
                          src={
                            "../../" +
                            process.env.PUBLIC_URL +
                            "assets/images/imgs/contact_img1.svg"
                          }
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <form
                      className="contact_sm_form"
                      onSubmit={(e) => this.submitForm(e)}
                      noValidate
                    >
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control overseas_input"
                          placeholder="Name"
                          name="name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({
                              name: e.target.value,
                            })
                          }
                        />
                        {error.nameErr && (
                          <p className="mb-0 error">{error.nameErr}</p>
                        )}
                      </div>
                      <div class="mb-3">
                        <input
                          type="email"
                          class="form-control overseas_input"
                          placeholder="Email"
                          name="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({
                              email: e.target.value,
                            })
                          }
                        />

                        {error.emailErr && (
                          <p className="mb-0 error">{error.emailErr}</p>
                        )}
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control overseas_input"
                          placeholder="Phone Number"
                          name="phone"
                          value={this.state.phone}
                          onChange={(e) =>
                            this.setState({
                              phone: e.target.value,
                            })
                          }
                        />

                        {error.phoneErr && (
                          <p className="mb-0 error">{error.phoneErr}</p>
                        )}
                      </div>
                      <div class="mb-2">
                        <div class="form-group">
                          <select
                            class="form-control overseas_input"
                            name=""
                            id=""
                            onChange={(e) =>
                              this.setState({
                                type: e.target.value,
                              })
                            }
                          >
                            {this.state.leadTypes ? (
                              <>
                                <option value="">Select Type</option>
                                {this.state.leadTypes.map((item, i) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </>
                            ) : (
                              <option value="">Select Type</option>
                            )}
                          </select>
                          {error.typeErr && (
                            <p className="mb-0 error">{error.typeErr}</p>
                          )}
                        </div>
                      </div>
                      <div class="">
                        {this.state.loader ? (
                          <button
                            type="button"
                            class="btn btn-primary mb-3 w-100 fw-800 d-flex justify-content-between align-items-center"
                          >
                            Sending...
                            {/* <i className="icomoon icon-chevron-right fw-800 overseas_btn"></i> */}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            class="btn btn-primary mb-3 w-100 fw-800 d-flex justify-content-between align-items-center"
                          >
                            Send{" "}
                            <i className="icomoon icon-chevron-right fw-800 overseas_btn"></i>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <footer>
          <div class="container py-5 ">
            <div className="footer_main_wrapper">
              <div className="footer_wrapper">
                <div class="row">
                  <div class="col-12 col-md">
                    <div className="img_wrapper">
                      <img
                        src={
                          "../../" +
                          process.env.PUBLIC_URL +
                          "assets/images/logo/logo_white.svg"
                        }
                      ></img>
                    </div>
                  </div>
                  <div class="col-6 col-md">
                    <h5 className="text-white">
                      <b>Resources</b>
                    </h5>
                    <ul class="list-unstyled text-small">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/courses">Find a Course</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6 col-md">
                    <h5 className="text-white">
                      <b>Study Abord</b>
                    </h5>
                    <ul class="list-unstyled text-small">
                      {this.state.ctryList &&
                        this.state.ctryList.map((ctry, i) => (
                          <li>
                            <Link to={`/study-abroad/` + ctry.slug}>
                              {ctry.name}
                            </Link>
                          </li>
                        ))}

                      {/* <li>
                        <Link to="">
                          UK
                       </Link>
                      </li>
                      <li>
                        <Link to="">
                          US
                       </Link>
                      </li>
                      <li>
                        <Link to="">
                          Singapore
                       </Link>
                      </li> */}
                    </ul>
                  </div>
                  {/* <div class="col-6 col-md">
                    <h5 className="text-white">
                      <b>Our Services</b>
                    </h5>
                    <ul class="list-unstyled text-small">
                      <li>
                        <Link to="">Career Counsling</Link>
                      </li>
                      <li>
                        <Link to="">NMC Test</Link>
                      </li>
                      <li>
                        <Link to="">Visa Assistance</Link>
                      </li>
                      <li>
                        <Link to="">IELTS training</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <p class=" mb-0 mt-5 text-muted">
                    Copyright &copy; 2021 OverseasEye All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  saveEq: state.data.saveEnq,
  crty_lst: state.data.ctry_list,
  ldTypes: state.data.leadsTypes,
});
export default connect(mapStateToProps, { saveEnquiry, getCountryList,getLeadsTypes })(
  Footer
);
