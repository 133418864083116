import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class WeAre extends Component {
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".we_are",
        start: "top center",
        // markers: true
      },
    });

    tl.fromTo(
      ".anme_item",
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
    tl.fromTo(
      ".banner_bg_color",
      {
        opacity: 0,
        width: 0,
        ease: "power1.in",
      },
      {
        opacity: 1,
        width: "76%",
        ease: "power1.out",
      }
    );
  }
  render() {
    return (
      <>
        <div className="container mt-2 we_are pt-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 d">
              <div className="slider_content_wrapper">
                <div className="slider_content">
                  <div className="circle"></div>
                  <p className="main_head_sm anme_item">We Are</p>
                  <h1 className="main_head_lg anme_item">OverseasEye</h1>
                  <p className="main_head_desc anme_item">
                    The Overseas Eye International has established its credentials
                    as a leading student recruitment firm.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-sm-none d-md-block d-none">
              <div className="slider_image_wrapper anme_item">
                <p>
                  Overseas Eye International commenced its operation earlier in
                  2013 for its domestic admission in and around India and later
                  in 2019, it started its International education in India with
                  full-fledged branches at Trivandrum, Kochi and Kottayam. The
                  Overseas Eye International has established its credentials as a
                  leading student recruitment firm. We have a well-defined
                  track of procedural precision and ethical standards in our
                  services to students and foreign institutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
