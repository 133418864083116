import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
export default class PartnersSec extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
        this.props.partners.length <= 6 ? this.props.partners.length : 6,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 5000,
      // cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow:
              this.props.partners.length >= 3 ? 3 : this.props.partners.length,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:
              this.props.partners.length >= 2 ? 2 : this.props.partners.length,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <div className="container position-relative partners_sec_price">
          <div className="sec_heading_wrapper">
            <div className="d-flex flex-column align-items-center">
              <p className="pill_head pr_bg_cl">Our</p>
              <h1 className="main_heabing">Partners</h1>
            </div>
          </div>
          <div className="pt-3">
            <div className="partners_wrapper">
              <Slider {...settings}>
                {this.props.partners.map((pt, i) => (
                  <div key={i} >
                    <div className="image_wrapper">
                      <img
                        src={
                          pt.image_url
                        }
                      ></img>
                    </div>
                  </div>
                ))}

                {/* <div className="image_wrapper">
                <img
                  src={process.env.PUBLIC_URL + "assets/images/logos/logo1.png"}
                ></img>
              </div>
              <div className="image_wrapper">
                <img
                  src={process.env.PUBLIC_URL + "assets/images/logos/logo2.png"}
                ></img>
              </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
