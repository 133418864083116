import React, { Component } from "react";

export default class Diagram extends Component {
  render() {
    return (
      <div className="container diagram_wrapper">
        <div className="col-lg-8">
          <div className="text-center">
            <h3>Permanent Resident 	</h3>
            <p>
              A person who has legally immigrated to Canada but is not yet a
              Canadian citizen, but where they have the right to reside on a
              permanent basis. The following are the main streams.
            </p>
          </div>
          <div className={'col-lg-8'}>
            <div className="img_wrapper">
            <img src={'../'+process.env.PUBLIC_URL+'/assets/images/imgs/diagram1.png'}></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
