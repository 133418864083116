import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import SideEnq from "../components/commonCmp/SideEnq";
import TopNav from "../components/commonCmp/TopNav";
import { connect } from "react-redux";
import { getCoursesDt, getCountryList } from "../redux/actions/dataAction";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Loader from "../components/commonCmp/Loader";
import store from "../redux/store";

class CourseDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseInfo: null,
      ctryLst: null,
    };
  }
  componentDidMount() {
    const { ctry_list } = store.getState().data;
    // console.log(ctry_list);
    if (ctry_list) {
      this.setState({
        ctryLst: ctry_list,
      });
    } else {
      this.props.getCountryList();
    }
    // console.log(this.props.match.params.slug);
    this.props.getCoursesDt({ slug: this.props.match.params.slug });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
   
    if (nextProps.courses !== this.props.courses) {
      if (nextProps.courses) {
        this.setState({
          courseInfo: nextProps.courses,
        });
      }
    }
    if (nextProps.crty_lst !== this.props.crty_lst) {
      if (nextProps.crty_lst) {
        this.setState({
          ctryLst: nextProps.crty_lst,
        });
      }
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    // console.log(this.state.courseInfo);
    return this.state.courseInfo ? (
      <>
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          <div className="container">
            <div className="course_details_wrapper">
              <h4 className="mb-3">{this.state.courseInfo.name}</h4>
              <div className="row">
                <div className="col-lg-2">
                  <div className="course_img_wrapper">
                    <img src={this.state.courseInfo.image_url}></img>
                  </div>
                  {/* <p className="course_price_title">Total Course Fee</p>
                  <p className="course_price_amt">$1200</p> */}
                </div>
                <div className="col-lg-6">
                  <div className="course_info">
                    <div className="course_tag_wrapper">
                      <div className="tag_item">
                        <i className="icomoon icon-education"></i>
                        <span>
                          {this.state.courseInfo.education_level_name}
                        </span>
                      </div>
                      <div className="tag_item ml-4">
                        <i className="icomoon icon-calendar"></i>
                        <span>{this.state.courseInfo.course_years} year</span>
                      </div>
                    </div>
                    <div className="mt-4 pb-5">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.courseInfo.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-5">
                  <SideEnq
                    country={this.state.courseInfo.country_id}
                    courseId={this.state.courseInfo.id}
                    ctryLst={this.state.ctryLst}
                    courseDt={true}
                  ></SideEnq>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <Loader></Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  courses: state.data.coursesDt,
  crty_lst: state.data.ctry_list,
});

export default connect(mapStateToProps, {
  getCoursesDt,
  getCountryList,
})(CourseDetails);
