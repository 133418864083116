import React, { Component } from "react";

export default class ServiceCard extends Component {
  render() {
    return (
      // <div className="col-lg-4">
      <div className="service_card_wrapper">
        <div className={`img_wrapper ${this.props.bg_color}`}>
          <img src={this.props.img}></img>
        </div>
        <div className="service_info_wrapper">
          <p className="service_heading">{this.props.heading}</p>
          <p className="service_desc">{this.props.desc}</p>
        </div>
      </div>
      // </div>
    );
  }
}
