import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

export default class TopNav extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
    };
  }

  render() {
    const { location } = this.props.history;
    console.log(this.props);

    const homeClass = location.pathname === "/" ? "active" : "";
    const imClass = location.pathname.match(/^\/immigration/) ? "active" : "";
    const abroadClass = location.pathname.match(/^\/study-abroad/)
      ? "active"
      : "";
    const findCourseClass = location.pathname.match(/^\/find-courses/)
      ? "active"
      : "";
    const abtClass = location.pathname.match(/^\/about-us/) ? "active" : "";
    const crseClass = location.pathname.match(/^\/courses/) ? "active" : "";
    const servicesClass = location.pathname.match(/^\/services/)
      ? "active"
      : "";
    const contactClass = location.pathname.match(/^\/contact-us/)
      ? "active"
      : "";
      
    return (
      <nav class="navbar navbar-expand-lg navbar-light">
        {this.state.showMenu && (
          <div
            className="overlay"
            onClick={() => this.setState({ showMenu: false })}
          ></div>
        )}
        <div class="container">
          <Link class="navbar-brand" to="/">
            <img
              src={
                "../../" +
                process.env.PUBLIC_URL +
                "assets/images/logo/logo.svg"
              }
            ></img>
          </Link>
          <button
            onClick={() =>
              this.setState({
                showMenu: true,
              })
            }
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class={`collapse navbar-collapse ${this.state.showMenu && "show"}`}
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <NavLink
                  class={`nav-link ${homeClass}`}
                  aria-current="page"
                  to="/"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class={`nav-link ${imClass}`}
                  to="/immigration"
                  activeClassName="active"
                >
                  Immigration
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class={`nav-link ${abroadClass}`}
                  to="/study-abroad"
                  activeClassName="active"
                >
                  Study Abroad
                </NavLink>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${findCourseClass} ${crseClass}`}
                  to="/courses"
                  activeClassName="active"
                >
                  Find a Course
                </Link>
              </li>
              <li class="nav-item">
                <Link class={`nav-link ${abtClass}`} to="/about-us">
                  About Us
                </Link>
              </li>

              <li class="nav-item">
                <Link class={`nav-link ${servicesClass}`} to="/services">
                  Our Services
                </Link>
              </li>
              <li class="nav-item">
                <Link class={`nav-link ${contactClass}`} to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
