import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
// import * as actions from "./actions/dataAction";
// import jwtDecode from "jwt-decode";


// import {  toast } from "react-toastify";

const initialState = {};

const authInterceptor = ({ dispatch }) => (next) => (action) => {
  // console.log("Middleware triggered:", action, action.payload);

  try {
    if (action.status === 401) {
      // console.log('entered')

      // store.dispatch(actions.logoutUser());
    } else {
      // if (localStorage.access_token) {
      //   let exp = jwtDecode(localStorage.access_token).exp;
      //   let expDate = new Date(exp * 1000)
      //   let difference = +new Date(expDate) - +new Date();
      //   let rem_min = Math.floor((difference / 1000 / 60) % 60);
      //   // console.log(rem_min);

      //   if (rem_min !== 0 && rem_min < 59) {


      //     // if (!localStorage.refresh) {
      //     //   localStorage.setItem('refresh', true)
      //       // return refreshToken(dispatch).then((e) => { console.log(e); next(action) });

      //     // }


      //   } else {
      //     next(action)
      //   }
      // }
      next(action);
    }
  } catch {
    next(action);
  }
};
const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
