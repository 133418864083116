import React, { Component } from "react";

export default class BannerSec5 extends Component {
  render() {
    return (
      <div className="position-relative cmn_anime mt-0">
        <div className="container">
          <div className="content_sec_wrapper">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="content_desc_wrapper">
                  <div className="content_container">
                    <p className="main_head_sm1">Why</p>
                    <h1 className="main_head_lg1">Canada Immigration?</h1>
                    <p className="main_head_desc">
                      Immigration has always been in focal point and its
                      importance kept growing along with the evolution of
                      mankind. Different countries are welcoming immigrants
                      through different pathways and lot of people are making
                      the best use of these opportunities. Among the immigration
                      friendly countries, Canada holds one among the prime
                      positions. Now the obvious question is, what makes Canada
                      the favourite destination to settle down for many? Even
                      though it has got a broader range of answers, we can
                      summarize it to few of the key characteristics which keeps
                      Canada always on the top list for any aspirant immigration
                      seeker.
                    </p>
                    <div className="mt-3">
                      {/* <button
                        type="button"
                        class="btn btn-primary rounded-pill"
                      >
                        Find out how
                      </button> */}
                      <ul>
                        <li>Better job opportunities with higher perks</li>
                        <li>Quality of life</li>
                        <li>Free global standard education facilities</li>
                        <li>Free medical services</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img_sec">
                  <div className="dots_img_wrapper dot_bottom">
                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/dots.png"
                      }
                    ></img>
                  </div>
                  <div className="image_wrapper">
                    <div className="circle3"></div>

                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/imgs/why_canada.jpg"
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
