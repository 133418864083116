import React, { Component } from "react";
import './preloader.css'

export default class Loader extends Component {
  render() {
    return (
      <>
        <div class="preloader">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 264 203"
            enable-background="new 0 0 264 203"
            // xml:space="preserve"
            xmlSpace="preserve"
            class="preloSvg"
          >
            <g id="XMLID_30_">
              <path
                class="squiggle"
                fill="none"
                fill="none"
                stroke="#ee2751"
                stroke-miterlimit="10"
                stroke-width="6"
                d="M142.8,152.9c-6.8-0.4-13.2-2.1-19.2-4.8l67.5-48.9l10.3-7.4l34-24.6l1.2-0.9L261.8,48l-31.5,7.3
			c-15.5-30.6-47.3-51.6-83.9-51.6c-51.5,0-93.5,41.6-94.1,93.1l-47,10.9l32.8,14.2l10.2,4.4c2.7,1.2,5.7,1.3,8.5,0.5l0,0l16.2-4.9
			l21-6.4l0,0c4.6-1.4,7.6,4.8,3.6,7.5l-0.3,0.2l-13.3,9l-18.9,12.9l0,0c-1.8,1.2-3.1,3.2-3.5,5.4L59,165.6l-5.9,33.6l36.5-26.5
			c15.8,12,35.5,19.1,56.8,19.1c51.4,0,93.3-41.4,94.1-92.6h-38.8C200.8,130.3,174.4,154.9,142.8,152.9z M92.1,87.6
			c5-26.7,29.4-46.7,57.9-44.9c16.5,1.1,31.1,9.5,40.5,22L92.1,87.6z"
              />
            </g>
          </svg>
        </div>
      </>
    );
  }
}
