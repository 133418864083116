import React, { Component } from "react";
import CounterCard from "../../components/card/counterCard";

export default class MissionSec extends Component {
  render() {
    return (
      <div className="container">
        <div className="mission_wrapper row">
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard
              num={"01"}
              title={"Customer-Centric"}
              desc={
                "The contentment and happiness of our clients is our greatest motivation. We give priority to your needs and suggest what is best and affordable for you. We have dedicated our service to your satisfaction."
              }
            ></CounterCard>
          </div>
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard
              num={"02"}
              title={"Solution-oriented"}
              desc={
                "With our dynamic team of experienced professionals and tie-ups with top-ranked international institutions, we are focused on directing you towards your dream destinations."
              }
            ></CounterCard>
          </div>
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard
              num={"03"}
              title={"Transparency"}
              desc={
                "Transparency and integrity are our fundamental values, and we provide you with step by step updates. Our pursuit is to utilize our skills and creativity to the utmost in achieving your goals."
              }
            ></CounterCard>
          </div>
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard
              num={"04"}
              title={"Accountable"}
              desc={
                "Our responsibility doesn't just end with enrolling students. We provide them with additional services to help them settle during the initial stages of their educational journey abroad."
              }
            ></CounterCard>
          </div>
        </div>
      </div>
    );
  }
}
