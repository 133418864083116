import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import QnASec from "../pageCmp/home/QnASec";
import { getFaq } from "../redux/actions/dataAction";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { connect } from "react-redux";

class Faq extends Component {
  constructor() {
    super();
    this.state = {
      slider: null,
      blogs: null,
      testi: null,
      partners: null,
      faqs: null,
      totalCount: null,
    };
  }
  componentDidMount() {
    this.props.getFaq({ page: 0, pagination_limit: 6 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.faq !== this.props.faq) {
      this.setState({
        faqs: nextProps.faq,
       
      });
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    return (
      <>
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          <div className="container">
            <QnASec
              faq={this.state.faqs}
              count={0}
              bgWhite={true}
            ></QnASec>
          </div>
        </div>
        <Footer hideFooter={false}></Footer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  faq: state.data.faqList,
});

export default connect(mapStateToProps, {
  getFaq,
})(Faq);
