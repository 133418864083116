import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialCard from "../../components/card/TestimonialCard";

export default class Testimonial extends Component {
  constructor() {
    super();
    this.state = {
      oldSlide: 0,
      activeSlide: 0,
      activeSlide2: 0,
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.testi.length < 3 ? this.props.testi.length : 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1635,
          settings: {
            slidesToShow:
              this.props.testi.length <= 2 ? this.props.testi.length : 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1110,
          settings: {
            slidesToShow:
              this.props.testi.length <= 1 ? this.props.testi.length : 1.9,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow:
              this.props.testi.length <= 1 ? this.props.testi.length : 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow:
              this.props.testi.length <= 1 ? this.props.testi.length : 1.6,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1.4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 688,
          settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 634,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
      ],
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
    };
    // console.log(this.state)

    return (
      <div className="testimonial_main_wrapper">
        <div className="circle"></div>
        {/* <div className="container position-relative"> */}
        <div className="col-12">
          <div className="ml_10_p">
            <h1 className="heading_sec">
              Know what our <span>Customers</span>{" "}
            </h1>
            <h1 className="heading_sec">say about our Services</h1>
          </div>

          <div className="testinomial_slider_wrpr">
            <Slider ref={(c) => (this.slider = c)} {...settings}>
              {this.props.testi &&
                this.props.testi.map((tsti, i) => (
                  <div>
                    <TestimonialCard testi={tsti}></TestimonialCard>
                  </div>
                ))}
            </Slider>
          </div>
          <div className="arrow_wrapper">
            <div className="arrow_left" onClick={() => this.previous()}>
              <i className="icomoon icon-arrow-left"></i>
            </div>
            <div className="arrow_right" onClick={() => this.next()}>
              <i className="icomoon icon-arrow-right"></i>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }
}
