import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from "react-router-dom";

export default class QnASec extends Component {
  render() {
    return (
      <div className={`qa_main_wrapper ${this.props.bgWhite && 'bg-white'}`}>
        <div className="container pt-5 pb-5">
          <div className="sec_heading_wrapper">
            <div className="d-flex flex-column align-items-center">
              <p className="sm_head">FAQ</p>
              <h1 className="main_heabing">Do we offer at the OverseasEye</h1>
            </div>
          </div>
          <div className="faq_accordion_wrapper mt-5">
            <Accordion>
              <div className="row">
                {this.props.faq &&
                  this.props.faq.map((faq, i) => (
                    <div className="col-lg-6">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <AccordionItemState>
                              {({ expanded }) =>
                                expanded ? (
                                  <i className="icomoon icon-minus1"></i>
                                ) : (
                                  <i className="icomoon icon-plus1"></i>
                                )
                              }
                            </AccordionItemState>
                            {faq.question}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{faq.answer}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                  ))}
              </div>
            </Accordion>
          </div>
          {this.props.count > 6 && (
            <div className="d-flex align-items-center justify-content-center">
              <Link  to="/faq" className="fw-800 more_btn text-decoration-none">See All</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}
