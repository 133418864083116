import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class HomNav extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll(e) {
    // let footer_height = document.getElementById("ft_main_wrapper").clientHeight;
    var w = window.innerHeight;

    var body = document.body;
    var html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    // console.log(w);
    // var f_height = height - (w - 100);
    // var sc_height = html.scrollTop + w;
    // console.log(html.scrollTop);
    if (html.scrollTop > 200) {
      var element = document.getElementById("navbar");
      element.classList.add("boxShadow");
    } else {
      var element = document.getElementById("navbar");
      element.classList.remove("boxShadow");
    }

    // if (parseInt(f_height) <= parseInt(sc_height)) {
    //   // alert();
    //   // if (this.state.nextUrl && this.state.prdtLoaderEn) {

    //   // }
    // }
  }
  render() {
    return (
      <nav class="navbar navbar-expand-lg navbar-light" id="navbar">
        {this.state.showMenu && (
          <div
            className="overlay"
            onClick={() => this.setState({ showMenu: false })}
          ></div>
        )}
        <div class="container">
          <a class="navbar-brand" href="#">
            <img
              src={process.env.PUBLIC_URL + "assets/images/logo/logo.svg"}
            ></img>
          </a>
          <button
            onClick={() =>
              this.setState({
                showMenu: true,
              })
            }
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class={`collapse navbar-collapse ${this.state.showMenu && "show"}`}
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link class="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/immigration">
                  Immigration
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/study-abroad">
                  Study Abroad
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/courses">
                  Find a Course
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/about-us">
                  About Us
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link" to="/services">
                  Our Services
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
