import React, { Component } from "react";
import CourseCardCat from "../../components/card/CourseCardCat";
import isEmpty from "../../redux/validation/is-Empty";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default class CourseSec extends Component {
  constructor(props) {
    super(props);
    // cards, elements tha will be used in the tween
    this.cards = [];
    // the timeline instance
    // this.tl = new TimelineLite({ paused: true });
  }
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".cat_sec",
    //     start: "top center",
    //     markers: false,
    //   },
    });
    tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  render() {
    return (
      <div className="row ">
        {!isEmpty(this.props.category) && (
          <>
            {this.props.category.map((cat, i) => (
              <div className="col-lg-3 col-md-4 col-sm-6" ref={(div) => (this.cards[i] = div)} key={i}>
                <CourseCardCat cat={cat} history={this.props.history} slug={this.props.slug}></CourseCardCat>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}
