import React, { Component } from "react";
import BlogsCard from "../../components/card/BlogsCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import isEmpty from "../../redux/validation/is-Empty";

export default class BlogsSec extends Component {
  constructor(props) {
    super(props);
    // cards, elements tha will be used in the tween
    this.cards = [];
    // the timeline instance
    // this.tl = new TimelineLite({ paused: true });
  }
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".bolgs_sec_wrapper",
        start: "top center",
        markers: false,
      },
    });
    tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  render() {
    return (
      <div className={`bolgs_sec_wrapper ${!this.props.main && "pt-5 pb-2"}`}>
        <div className="container">
          <div className="sec_heading_wrapper">
            <div className="d-flex flex-column align-items-center">
              <p className="pill_head pr_bg_cl">Blogs</p>
              {/* <h1 className="main_heabing">Do we offer at the OverseasEye</h1> */}
              <p className="sub_heabing">Don’t miss our recent articles</p>
              {!this.props.main && (
                <div className="d-flex align-items-center justify-content-center ">
                  <Link to="/blogs" className="fw-800 more_btn text-decoration-none">
                    See All
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="row justify-content-center d-flex">
              {/* <BlogsCard history={this.props.history}></BlogsCard>
              <BlogsCard history={this.props.history}></BlogsCard>
              <BlogsCard history={this.props.history}></BlogsCard> */}
              {!this.props.main
                ? this.props.blogs &&
                  this.props.blogs.slice(0, 3).map((blg, i) => (
                    <div
                      className="col-lg-3 mb-4"
                      ref={(div) => (this.cards[i] = div)}
                      key={i}
                    >
                      <BlogsCard
                        history={this.props.history}
                        blg={blg}
                      ></BlogsCard>
                    </div>
                  ))
                : this.props.blogs &&
                  this.props.blogs.map((blg, i) => (
                    <div
                      className="col-lg-3 mb-4"
                      ref={(div) => (this.cards[i] = div)}
                      key={i}
                    >
                      <BlogsCard
                        history={this.props.history}
                        blg={blg}
                      ></BlogsCard>
                    </div>
                  ))}
              {/* {this.props.main && (
                <>
                  <BlogsCard history={this.props.history}></BlogsCard>
                  <BlogsCard history={this.props.history}></BlogsCard>
                  <BlogsCard history={this.props.history}></BlogsCard>
                  <BlogsCard history={this.props.history}></BlogsCard>
                  <BlogsCard history={this.props.history}></BlogsCard>
                </>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
