import React, { Component } from "react";
import CourseCard from "../components/card/CourseCard";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import ServicesSec from "../pageCmp/home/ServicesSec";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getCareerList,
  getCountryList,
  getEduLevel,
  getCourses,
  getCategoryList,
  getCoursesMore,
  getCategoryDetails,
} from "../redux/actions/dataAction";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import queryString from "query-string";
import isEmpty from "../redux/validation/is-Empty";
import Loader from "../components/commonCmp/Loader";
import ScrollManager from "../components/commonCmp/ScrollManager";

const placeholder = "Select Category...";
const placeholderCtry = "Select Country...";
const placeholderEdu = "Select Eductaion...";

class AllCourse extends Component {
  constructor() {
    super();
    this.state = {
      career: null,
      ctryList: null,
      ctryId: "",
      countryId: null,
      eduId: "",
      courses: null,
      ed_level: "",
      category: null,
      catId: "",
      selectedCtry: null,
      selectedCat: null,
      selectedEdu: null,
      isEmptyCourses: false,
      nextUrl: null,
      prdtLoaderEn: true,
      catDtls: null,
      mainImg:null,
    };
    this.cards = [];
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    // let params = queryString.parse(this.props.location.search);
    // console.log(this.props);
    window.addEventListener("scroll", this.handleScroll);
    const { params } = this.props.match;
    // console.log(params);
    var count = Object.keys(params).length;
    // console.log(count);
    if (count === 1) {
      this.setState(
        {
          ctryId: "",
          catId: params.category !== "" ? params.category : "",
        },
        () => this.getCrseList()
      );
    } else if (count === 2) {
      this.setState(
        {
          ctryId: params.country !== "" ? params.country : "",
          catId: params.category !== "" ? params.category : "",
        },
        () => this.getCrseList()
      );
    } else {
      this.getCrseList();
    }
    this.props.getCategoryDetails(params.category);

    // if (params) {
    //   this.setState(
    //     {
    //       ctryId: params.country !== "" ? parseInt(params.country) : "",
    //       catId: params.category !== "" ? parseInt(params.category) : "",
    //     },
    //     () => this.getCrseList()
    //   );
    // } else {
    //   this.getCrseList();
    // }
    this.props.getCareerList();

    this.props.getCountryList();
    gsap.registerPlugin(ScrollTrigger);

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".all_crse",
        start: "top center",
        markers: false,
      },
    });
    this.tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll(e) {
    let footer_height = document.getElementById("ft_main_wrapper").clientHeight;
    var w = window.innerHeight;

    var body = document.body;
    var html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    var f_height = height - footer_height;
    var sc_height = html.scrollTop + w;

    if (parseInt(f_height) <= parseInt(sc_height)) {
      if (this.state.nextUrl && this.state.prdtLoaderEn) {
        this.loadMoreProducts();
      }
      // this.endReached();

      // document.getElementById("filter").style.display = "none";
    }
  }
  getCrseList() {
    this.props.getCourses({
      education_level_id: this.state.eduId,
      category: this.state.catId,
      country: this.state.ctryId,
    });
    // console.log(this.state.ctryId !== "");
    this.props.getCategoryList({ country: this.state.ctryId });
    if (this.state.ctryId !== "") {
      this.props.getEduLevel({ country: this.state.ctryId });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.catDt !== this.props.catDt) {
      this.setState({
        catDtls: nextProps.catDt,
      });
    }
    if (nextProps.cat !== this.props.cat) {
      this.setState(
        {
          category: nextProps.cat,
        },
        () => this.getParamValue()
      );
    }
    if (nextProps.career !== this.props.career) {
      this.setState({
        career: nextProps.career,
      });
    }

    if (nextProps.crty_lst !== this.props.crty_lst) {
      if (nextProps.crty_lst) {
        this.setState(
          {
            ctryList: nextProps.crty_lst,
          },
          () => this.getParamValue()
        );
      }
    }
    if (nextProps.courses !== this.props.courses) {
      if (nextProps.courses) {
        this.setState({
          courses: nextProps.courses.data,
          nextUrl: nextProps.courses.next_page_url,
          isEmptyCourses: isEmpty(nextProps.courses.data),
        });
      }
    }
    if (nextProps.ed_level !== this.props.ed_level) {
      if (nextProps.ed_level) {
        this.setState({
          ed_level: nextProps.ed_level,
        });
      }
    }
    if (nextProps.coursesMore !== this.props.coursesMore) {
      if (!isEmpty(nextProps.coursesMore)) {
        this.setState({
          courses: [...this.state.courses, ...nextProps.coursesMore.data],
          nextUrl: nextProps.coursesMore.next_page_url,
          prdtLoaderEn: nextProps.coursesMore.next_page_url ? true : false,
          prdtLoader: nextProps.coursesMore.next_page_url ? true : false,
        });
      }
    }
  }
  getParamValue() {
    if (!isEmpty(this.state.ctryList)) {
      var ctrye = null;
      var ctryID = null;
      this.state.ctryList.map((ctry) => {
        if (ctry.slug === this.state.ctryId) {
          ctrye = { value: ctry.slug, label: ctry.name };
          ctryID = ctry.id;
          // this.props.getCategoryList({ country: ctry.slug });
        }
        this.setState({
          selectedCtry: ctrye,
          countryId: ctryID,
        });
      });
    }
    if (!isEmpty(this.state.category)) {
      var catee = null;
      this.state.category.map((ctry) => {
        if (ctry.slug === this.state.catId) {
          catee = { value: ctry.slug, label: ctry.name };
        }
        this.setState({
          selectedCat: catee,
        });
      });
    }
  }
  selectCounrty(value) {
    this.setState(
      {
        ctryId: value.value,
        // selectedCat: null,
        // catId: "",
        selectedEdu: null,
        eduId: "",
        courses: null,
        ed_level: null,
        selectedCtry: value,
        countryId: value.id,
      },
      () => this.ctryChange()
    );
    // console.log(value);
    this.props.getCategoryList({ country: value.value });
    this.props.getEduLevel({ country: value.id });
    // this.props.getEduLevel({ country: value.value });
  }
  ctryChange() {
    // console.log({
    //   education_level_id: this.state.eduId,
    //   category: this.state.catId,
    //   country: this.state.ctryId,
    // });
    this.props.getCourses({
      education_level_id: this.state.eduId,
      category: this.state.catId,
      country: this.state.ctryId,
    });
  }
  edChange(value) {
    this.setState({
      eduId: value.value,
      courses: [],
      selectedEdu: value,
    });
    this.props.getCourses({
      education_level_id: value.value,
      category: this.state.catId,
      country: this.state.ctryId,
    });

    // this.props.getEduLevel({ country: value.value });
  }
  selectCategory(value) {
    this.setState(
      {
        catId: value.value,
        courses: [],
        ctryId: "",
        // selectedCat: null,
        // catId: "",
        selectedEdu: null,
        eduId: "",
        
        ed_level: null,
        selectedCtry: null,
        countryId: null,
      },
      () => this.getParamValue()
    );
    this.props.getEduLevel({ country: this.state.countryId });
    this.props.getCategoryDetails(value.value);
    this.props.getCourses({
      education_level_id: this.state.eduId,
      category: value.value,
      country: this.state.ctryId,
    });
  }
  loadMoreProducts() {
    // console.log(this.state.nextUrl);
    if (this.state.nextUrl && this.state.prdtLoaderEn) {
      // console.log("BOTTOM REACHED:");
      this.setState({
        prdtLoader: true,
        prdtLoaderEn: false,
      });
      let url = queryString.parseUrl(this.state.nextUrl);
      this.props.getCoursesMore({
        education_level_id: this.state.eduId,
        category: this.state.catId,
        country: this.state.ctryId,
        page: url.query.page,
      });
    }
  }
  render() {
    // var ctrys = [{ value: "", label: "Select Country" }];
    // console.log(this.state);
    var ctrys = [];
    var ed_lvl = [];
    var cate = [];
    if (this.state.ctryList) {
      this.state.ctryList.map((ctry, i) =>
        ctrys.push({ value: ctry.slug, label: ctry.name, id: ctry.id })
      );
    }
    if (this.state.ed_level) {
      this.state.ed_level.map((ed, i) =>
        ed_lvl.push({ value: ed.id, label: ed.name })
      );
    }
    if (this.state.category) {
      this.state.category.map((ed, i) =>
        cate.push({ value: ed.slug, label: ed.name })
      );
    }

    // console.log(this.state);
    return this.state.courses ? (
      <>
        {/* <ScrollManager
          onEndReached={this.loadMoreProducts.bind(this)}
          height={0}
        > */}
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper all_crse mt-0">
          <div className="course_list_wrapper">
            <div className="all_filter_course_wrapper">
              <img
                src={
                  "../../" +
                  process.env.PUBLIC_URL +
                  "assets/images/imgs/course_bg.png"
                }
              ></img>
              <div className="form_wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <Select
                        value={this.state.selectedCat}
                        onChange={(v) => this.selectCategory(v)}
                        options={cate}
                        placeholder={placeholder}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Select
                        value={this.state.selectedCtry}
                        onChange={(v) => this.selectCounrty(v)}
                        options={ctrys}
                        placeholder={placeholderCtry}
                      />
                    </div>

                    <div className="col-lg-4">
                      <Select
                        value={this.state.selectedEdu}
                        onChange={(v) => this.edChange(v)}
                        options={ed_lvl}
                        placeholder={placeholderEdu}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-5 mb-5 testt">
              <h4>{this.state.catDtls.name}</h4>
              <p
                className="mb-2"
                dangerouslySetInnerHTML={{
                  __html: this.state.catDtls.description,
                }}
              ></p>

              {!isEmpty(this.state.courses) ? (
                this.state.courses.map((c, i) => (
                  <div
                    className="course_card_wrapper"
                    ref={(div) => (this.cards[i] = div)}
                    key={i}
                    key={i}
                  >
                    <CourseCard
                      history={this.props.history}
                      data={c}
                      key={i}
                      catDt={this.state.catDtls}
                    ></CourseCard>
                  </div>
                ))
              ) : this.state.isEmptyCourses ? (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <h4 className="fw-600">No Results Found!!!</h4>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlinkHref="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 264 203"
                    enable-background="new 0 0 264 203"
                    // xml:space="preserve"
                    xmlSpace="preserve"
                    class="preloSvg"
                  >
                    <g id="XMLID_30_">
                      <path
                        class="squiggle"
                        fill="none"
                        fill="none"
                        stroke="#ee2751"
                        stroke-miterlimit="10"
                        stroke-width="6"
                        d="M142.8,152.9c-6.8-0.4-13.2-2.1-19.2-4.8l67.5-48.9l10.3-7.4l34-24.6l1.2-0.9L261.8,48l-31.5,7.3
			c-15.5-30.6-47.3-51.6-83.9-51.6c-51.5,0-93.5,41.6-94.1,93.1l-47,10.9l32.8,14.2l10.2,4.4c2.7,1.2,5.7,1.3,8.5,0.5l0,0l16.2-4.9
			l21-6.4l0,0c4.6-1.4,7.6,4.8,3.6,7.5l-0.3,0.2l-13.3,9l-18.9,12.9l0,0c-1.8,1.2-3.1,3.2-3.5,5.4L59,165.6l-5.9,33.6l36.5-26.5
			c15.8,12,35.5,19.1,56.8,19.1c51.4,0,93.3-41.4,94.1-92.6h-38.8C200.8,130.3,174.4,154.9,142.8,152.9z M92.1,87.6
			c5-26.7,29.4-46.7,57.9-44.9c16.5,1.1,31.1,9.5,40.5,22L92.1,87.6z"
                      />
                    </g>
                  </svg>
                </div>
              )}
              {this.state.prdtLoader && (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlinkHref="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 264 203"
                    enable-background="new 0 0 264 203"
                    // xml:space="preserve"
                    xmlSpace="preserve"
                    class="preloSvg"
                  >
                    <g id="XMLID_30_">
                      <path
                        class="squiggle"
                        fill="none"
                        fill="none"
                        stroke="#ee2751"
                        stroke-miterlimit="10"
                        stroke-width="6"
                        d="M142.8,152.9c-6.8-0.4-13.2-2.1-19.2-4.8l67.5-48.9l10.3-7.4l34-24.6l1.2-0.9L261.8,48l-31.5,7.3
  c-15.5-30.6-47.3-51.6-83.9-51.6c-51.5,0-93.5,41.6-94.1,93.1l-47,10.9l32.8,14.2l10.2,4.4c2.7,1.2,5.7,1.3,8.5,0.5l0,0l16.2-4.9
  l21-6.4l0,0c4.6-1.4,7.6,4.8,3.6,7.5l-0.3,0.2l-13.3,9l-18.9,12.9l0,0c-1.8,1.2-3.1,3.2-3.5,5.4L59,165.6l-5.9,33.6l36.5-26.5
  c15.8,12,35.5,19.1,56.8,19.1c51.4,0,93.3-41.4,94.1-92.6h-38.8C200.8,130.3,174.4,154.9,142.8,152.9z M92.1,87.6
  c5-26.7,29.4-46.7,57.9-44.9c16.5,1.1,31.1,9.5,40.5,22L92.1,87.6z"
                      />
                    </g>
                  </svg>
                </div>
              )}
              {/* <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard> */}
            </div>
          </div>
        </div>
        <Footer></Footer>
        {/* </ScrollManager> */}
      </>
    ) : (
      <Loader></Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  career: state.data.career_list,
  crty_lst: state.data.ctry_list,
  courses: state.data.courses,
  coursesMore: state.data.loadMoreCourse,
  ed_level: state.data.edu_level,
  cat: state.data.course_list,
  catDt: state.data.catDet,
});

export default connect(mapStateToProps, {
  getCareerList,
  getCountryList,
  getEduLevel,
  getCourses,
  getCategoryList,
  getCoursesMore,
  getCategoryDetails,
})(AllCourse);
