import React, { Component } from "react";

export default class BannerSec2 extends Component {
  render() {
    return (
      <div className="position-relative cmn_anime">
        <div className="container">
          <div className="content_sec_wrapper">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="content_desc_wrapper">
                  <div className="content_container">
                    <p className="main_head_sm">Why Nurses</p>
                    <h1 className="main_head_lg">Work Abroad?</h1>
                    <p className="main_head_desc">
                      The health care industry is a booming one. It is one such
                      area which is recession proof. No matter what's the
                      economic status of a nation-developing or developed, there
                      is always a demand for health care services.
                    </p>
                    <div className="mt-3">
                      {/* <button
                        type="button"
                        class="btn btn-primary rounded-pill"
                      >
                        Find out how
                      </button> */}
                      <div class="button-container-2">
                        <span class="mas rounded-pill">Read more</span>
                        <button
                          type="button"
                          class="btn btn-primary rounded-pill"
                          onClick={() =>
                            this.props.history.push("/service/nurses-abroad")
                          }
                        >
                          Read more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img_sec">
                  <div className="dots_img_wrapper dot_bottom">
                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/dots.png"
                      }
                    ></img>
                  </div>
                  <div className="image_wrapper">
                    <div className="circle3"></div>
                    <div className="image_items_info full-border item1">
                      <div className="position-relative">
                        <div className="check_item_wrapper">
                          <div className="check_inner_wrapper">
                            <i className="icomoon icon-check"></i>
                          </div>
                        </div>
                        <div className="image_item_content">
                          <span className="content_sm">Your</span>
                          <span className="content_lg">Career Success</span>
                        </div>
                      </div>
                    </div>
                    <div className="image_items_info full-border item3">
                      <div className="position-relative">
                        <div className="check_item_wrapper cl_gr_ligth">
                          <div className="check_inner_wrapper cl_gr">
                            <i className="icomoon icon-check"></i>
                          </div>
                        </div>
                        <div className="image_item_content">
                          <span className="content_sm">Your</span>
                          <span className="content_lg">Higher Education</span>
                        </div>
                      </div>
                    </div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/imgs/nurses_abroad.jpg"
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
