import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import BlogsSec from "../pageCmp/home/BlogsSec";
import ServicesSec from "../pageCmp/home/ServicesSec";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { saveEnquiry, getLeadsTypes } from "../redux/actions/dataAction";
import { connect } from "react-redux";
import isEmpty from "../redux/validation/is-Empty";
import { toast } from "react-toastify";

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      comment: "",
      phone: "",
      loader: false,
      leadTypes: null,
      type: "",
      error: {
        nameErr: "",
        emailErr: "",
        subjectErr: "",
        typeErr: null,
      },
    };
  }
  componentDidMount() {
    this.props.getLeadsTypes();
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.saveEq !== this.props.saveEq) {
      if (nextProps.saveEq) {
        if (!isEmpty(nextProps.saveEq)) {
          this.setState({
            loader: false,
            name: "",
            email: "",
            subject: "",
            comment: "",
            phone: "",
            type: "",
            error: {
              nameErr: "",
              emailErr: "",
              subjectErr: "",
            },
          });
        }
      }
    }
    if (nextProps.ldTypes !== this.props.ldTypes) {
      if (nextProps.ldTypes) {
        this.setState({
          leadTypes: nextProps.ldTypes,
        });
      }
    }
  }
  formValidator() {
    let isValid = false;
    let nm = false,
      em = false,
      psw = false,
      tp = false,
      sub = false;
    let rEmailErr =
      this.state.email.length <= 0
        ? "Email is required!"
        : /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            this.state.email
          )
        ? (em = true)
        : "Email id is not valid!";

    let tpErr = this.state.type.length <= 0 ? "Type is required!" : (tp = true);

    // let subErr =
    //   this.state.subject.length <= 0 ? "Subject is required!" : (sub = true);

    this.setState({
      error: {
        emailErr: rEmailErr,
        typeErr: tpErr,
      },
    });
    if (em && tp) {
      isValid = true;
    }
    return isValid;
  }
  submitForm(e) {
    e.preventDefault();
    // toast.success("Wow so easy!")
    if (this.formValidator()) {
      // alert();
      this.setState({
        loader: true,
      });
      const data = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        comment: this.state.comment,
        phone: this.state.phone,
        lead_type_id: this.state.type,
        course_id: "",
        country_id: "",
        user_id: "",
      };
      this.props.saveEnquiry(data);
    }
  }
  render() {
    // console.log(this.state);
    const { error } = this.state;
    return (
      <>
        <TopNav history={this.props} history={this.props.history}></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          <div className="container">
            <div className="contact_us_wrapper">
              <h6 className="sm_head">Get excited already!!</h6>
              <h4 className="lg_head">Reach me!</h4>
              <div className="row mt-4">
                <div className="col-lg-4">
                  <div className="row">
                    <div class="col-sm-2">
                      <div className="icon_wrpr">
                        <i className="icomoon icon-phone-incoming"></i>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div className="contact_info">
                        <h6>Contact</h6>
                        <p>
                          <i className="icomoon icon-phone mr-2"></i>{" "}
                          <span>+91 91886 55551</span>
                        </p>
                        <p>
                          <i className="icomoon icon-phone mr-2"></i>
                          <span> +91 94006 55551</span>
                        </p>
                        <p>
                          <i className="icomoon icon-mail mr-2"></i>{" "}
                          <span>Info@overseaseye.com</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div class="col-sm-2">
                      <div className="icon_wrpr">
                        <i className="icomoon icon-clock"></i>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div className="contact_info">
                        <h6>Hours of operation</h6>
                        <p>Monday - Saturday :9 am -5 pm</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div class="col-sm-2">
                      <div className="icon_wrpr">
                        <i className="icomoon icon-pin"></i>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div className="contact_info">
                        <h6>Address</h6>
                        <p className="fw-800 mb-0">Overseas-Eye LLP.</p>
                        <p>
                          Loyola Building Thambalakkadu Road Kanjirappally,
                          Kerala,India, PIN:686507
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact_form_wrapper mb-5">
                <div className="row mt-5 d-flex justify-content-between">
                  <div className="col-lg-7">
                    <form
                      class="row g-3 "
                      onSubmit={(e) => this.submitForm(e)}
                      noValidate
                    >
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control contact_inp"
                          placeholder="Name "
                          name="name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({
                              name: e.target.value,
                            })
                          }
                        />
                        {error.nameErr && (
                          <p className="mb-0 error">{error.nameErr}</p>
                        )}
                      </div>
                      <div class="col-md-6">
                        <input
                          type="email"
                          class="form-control contact_inp"
                          placeholder="Email *"
                          name="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({
                              email: e.target.value,
                            })
                          }
                        />
                        {error.emailErr && (
                          <p className="mb-0 error">{error.emailErr}</p>
                        )}
                      </div>
                      <div class="col-md-6 mt-3">
                        <input
                          type="text"
                          class="form-control contact_inp"
                          placeholder="Subject "
                          name="subject"
                          value={this.state.subject}
                          onChange={(e) =>
                            this.setState({
                              subject: e.target.value,
                            })
                          }
                        />
                        {error.subjectErr && (
                          <p className="mb-0 error">{error.subjectErr}</p>
                        )}
                      </div>
                      <div class="col-md-6 mt-3">
                        <input
                          type="text"
                          class="form-control contact_inp"
                          placeholder="Phone "
                          name="phone"
                          value={this.state.phone}
                          onChange={(e) =>
                            this.setState({
                              phone: e.target.value,
                            })
                          }
                        />
                        {error.subjectErr && (
                          <p className="mb-0 error">{error.subjectErr}</p>
                        )}
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="form-group">
                          <select
                            class="form-control contact_inp"
                            name=""
                            id=""
                            onChange={(e) =>
                              this.setState({
                                type: e.target.value,
                              })
                            }
                          >
                            {this.state.leadTypes ? (
                              <>
                                <option value="">Select Type</option>
                                {this.state.leadTypes.map((item, i) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </>
                            ) : (
                              <option value="">Select Type</option>
                            )}
                          </select>
                          {error.typeErr && (
                            <p className="mb-0 error">{error.typeErr}</p>
                          )}
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <textarea
                          class="form-control contact_textarea"
                          placeholder="Leave a comment here"
                          rows={8}
                          name="comment"
                          value={this.state.comment}
                          onChange={(e) =>
                            this.setState({
                              comment: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      {this.state.loader ? (
                        <div class="col-12 mt-3">
                          <button type="button" class="btn btn-primary btn-lg">
                            Sending...
                          </button>
                        </div>
                      ) : (
                        <div class="col-12 mt-3">
                          <button type="submit" class="btn btn-primary btn-lg">
                            Submit
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                  <div className="col-lg-5">
                    <div className="img_wrapper">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/imgs/contact_img.png"
                        }
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideFooter={true}></Footer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  saveEq: state.data.saveEnq,
  ldTypes: state.data.leadsTypes,
});
export default connect(mapStateToProps, { saveEnquiry, getLeadsTypes })(
  Contact
);
