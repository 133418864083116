import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class WeDo extends Component {
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".cmn_anime",
        start: "top center",
        // markers: true,
      },
    });

    tl.fromTo(
      ".test",
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
    tl.fromTo(
      ".banner_bg_color",
      {
        opacity: 0,
        width: 0,
        ease: "power1.in",
      },
      {
        opacity: 1,
        width: "76%",
        ease: "power1.out",
      }
    );

    //  tl.to(this.mainHead, 2, { y: 10 })
    //  tl.to(this.mainHead, 1, { y: 0, transformOrigin: "center" });
    // this.logoTween.play();
    // this.logoTween1.play();
    //this.logoTween2.play();
  }
  render() {
    return (
      <div className="position-relative cmn_anime">
        <div className="container">
          <div className="content_sec_wrapper">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="img_sec">
                  <div className="dots_img_wrapper">
                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/dots.png"
                      }
                    ></img>
                  </div>
                  <div className="image_wrapper">
                    <div className="circle1"></div>
                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/about_us_what_we_do.jpg"
                      }
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content_desc_wrapper">
                  <div className="content_container">
                    <p className="main_head_sm test">What</p>
                    <h1 className="main_head_lg test">We Do</h1>
                    <p className=" test">
                      Our core vision is to impart the highest quality service
                      to our candidates thus fulfilling their dreams and
                      aspirations of attaining higher education overseas. We are
                      here to be the inspiration and professional guide for your
                      study abroad dreams. With the rapid expansions of our
                      network across many states and cities have made us evolve
                      as one of the best study abroad consultants in India.
                    </p>
                    <div className="mt-3">
                      {/* <button
                        type="button"
                        class="btn btn-primary rounded-pill"
                      >
                        Find out how
                      </button> */}
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
