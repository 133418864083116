import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import HomNav from "../commonCmp/HomNav";

export default class HomeSlider extends Component {
  componentDidMount() {}
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    return (
      <div className="home_slider">
        {/* <div className="col-12 mt-3">
					<img
						src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/16327/logo-man.svg"
						alt=""
						className="img-fluid logo"
						ref={ img => this.logoContainer = img }
					/>
				</div> */}
        <HomNav></HomNav>
        <Slider {...settings}>
          {this.props.slider &&
            this.props.slider.map((sl, i) => (
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d">
                      <div className="slider_content_wrapper">
                        <div className="slider_content">
                          <div className="circle"></div>
                          <p className="main_head_sm">{sl.heading1}</p>
                          <h1 className="main_head_lg">{sl.heading2}</h1>
                          <p className="main_head_desc">{sl.description}</p>
                          {sl.button_text && (
                            <div className="col-12 mt-3">
                              <button
                                type="button"
                                class="btn btn-primary rounded-pill"
                                onClick={() =>
                                  this.props.history.push(sl.button_url)
                                }
                              >
                                {sl.button_text}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-sm-none d-md-block d-none">
                      <div className="slider_image_wrapper">
                        <div className="image_wrapper">
                          <img src={sl.image_url}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {/* <div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider_content_wrapper">
                    <div className="slider_content">
                      <div className="circle"></div>
                      <p className="main_head_sm">What do we offer at</p>
                      <h1 className="main_head_lg">Overseas Eye?</h1>
                      <p className="main_head_desc">
                        Proin eget tortor risus. Quisque velit nisi, pretium ut
                        lacinia in, elementum id enim. Mauris blandit aliquet
                        elit, eget tincidunt nibh pulvinar a.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="slider_image_wrapper">
                    <div className="image_wrapper">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/slider/sl1.png"
                        }
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider_content_wrapper">
                    <div className="slider_content">
                      <div className="circle"></div>
                      <p className="main_head_sm">What do we offer at</p>
                      <h1 className="main_head_lg">Overseas Eye?</h1>
                      <p className="main_head_desc">
                        Proin eget tortor risus. Quisque velit nisi, pretium ut
                        lacinia in, elementum id enim. Mauris blandit aliquet
                        elit, eget tincidunt nibh pulvinar a.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="slider_image_wrapper">
                    <div className="image_wrapper">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/slider/sl1.png"
                        }
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div> */}
          {/* <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider_content_wrapper">
                    <div className="slider_content">
                      <div className="circle"></div>
                      <p className="main_head_sm">What do we offer at</p>
                      <h1 className="main_head_lg">Overseas Eye?</h1>
                      <p className="main_head_desc">
                        Proin eget tortor risus. Quisque velit nisi, pretium ut
                        lacinia in, elementum id enim. Mauris blandit aliquet
                        elit, eget tincidunt nibh pulvinar a.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="slider_image_wrapper">
                    <div className="image_wrapper">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/slider/sl1.png"
                        }
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </Slider>
      </div>
    );
  }
}
