import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default class Masonryy extends Component {
  constructor(props) {
    super(props);

    this.cards = [];
  }
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    // this.props.getCountryList();

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".abroadd",
        start: "top center",
        markers: false,
      },
    });
    tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  render() {
    return (
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={3} gutter="10px" gutter={40}>
          {this.props.ctryList.map((image, i) => (
            <div
              className="masonry_item"
              onClick={() => this.props.history.push("/study-abroad/"+image.slug)}
              ref={(div) => (this.cards[i] = div)}
              key={i}
            >
              <img
                key={i}
                src={image.image_url}
                style={{ width: "100%", display: "block" }}
              />
              <p>{image.name}</p>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    );
  }
}
