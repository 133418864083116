import React, { Component } from "react";

export default class BannerSec3 extends Component {
  render() {
    return (
      <div className="position-relative cmn_anime pt-5">
        <div className="container">
          <div className="content_sec_wrapper">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="content_desc_wrapper">
                  <div className="content_container">
                    <p className="main_head_sm1">OverseasEye</p>
                    <h1 className="main_head_lg1">Canada Immigration</h1>
                    <p className="main_head_desc">
                      Overseas-Eye is one of the prestigious organisations
                      specialised in Canada Immigration. Overseas-Eye has its
                      home office in Canada helping in meeting the best
                      immigration options available that meets the requirement
                      of clients. Our area of specialization:
                    </p>
                    <div className="mt-3">
                      {/* <button
                        type="button"
                        class="btn btn-primary rounded-pill"
                      >
                        Find out how
                      </button> */}
                      <ul>
                        <li>Permanent Immigration (Express Entry)</li>
                        <li>Temporary Resident Immigration</li>
                        <li>Business Immigration</li>
                        <li>Live in Caregiver program</li>
                        <li>Quebec Immigration</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img_sec">
                  <div className="dots_img_wrapper dot_bottom">
                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/dots.png"
                      }
                    ></img>
                  </div>
                  <div className="image_wrapper">
                    <div className="circle3"></div>

                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/immigration_img.jpg"
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
