import React, { Component } from 'react'

export default class EmTypeCard extends Component {
    render() {
        return (
            <div className="em_type_card_wrapper">
                <img src={this.props.img}></img>
                <p>{this.props.title}</p>
            </div>
        )
    }
}
