import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import BannerSec1 from "../pageCmp/home/BannerSec1";
import Mission from "../pageCmp/home/Mission";
import MissionSec from "../pageCmp/home/MissionSec";
import WeAre from "../pageCmp/home/WeAre";
import WeDo from "../pageCmp/home/WeDo";

export default class About extends Component {
  render() {
    return (
      <div>
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        <div className="content_wrapper mt-5 mb-5 pt-5">
          <WeAre></WeAre>
          <WeDo></WeDo>
          <Mission></Mission>
          <MissionSec></MissionSec>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
