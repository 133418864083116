import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import BlogsSec from "../pageCmp/home/BlogsSec";
import ServicesSec from "../pageCmp/home/ServicesSec";
import { getBlogDetails } from "../redux/actions/dataAction";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Loader from "../components/commonCmp/Loader";
import Popover from "react-popover";
import {
  FacebookShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  HatenaShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from "react-share";

class BlogDetails extends Component {
  constructor() {
    super();
    this.state = {
      blg_details: null,
      isOpen: false,
    };
  }
  componentDidMount() {
    // console.log(this.props.match.params);

    this.props.getBlogDetails({ slug: this.props.match.params.slug });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.blogs !== this.props.blogs) {
      if (nextProps.blogs) {
        this.setState({
          blg_details: nextProps.blogs,
        });
      }
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  togglePopover() {
   if(this.state.isOpen){
    this.setState({
      isOpen: false,
    });
   }else{
    this.setState({
      isOpen: true,
    });
   }
  }
  render() {
    // console.log(this.state.blg_details.img_url)
    return this.state.blg_details ? (
      <>
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper ">
          <div className="container pb-5 pt-3">
            <div className="d-flex justify-content-center">
              <div className="col-12 col-lg-12">
                <div className="blog_details_wrapper">
                  <div className="img_wrapper">
                    <img
                      src={
                        this.state.blg_details.image_url
                        // process.env.PUBLIC_URL +
                        // "assets/images/imgs/blog_bg.png"
                      }
                    ></img>
                  </div>
                  <h3 className="mt-3">{this.state.blg_details.title}</h3>
                  <div className="tag_n_share_wrpr">
                    <div className="tag_wrpr_left">
                      <div className="tag_item">
                        <i className="icomoon icon-calendar1"></i>
                        {/* <span>Dec 28,2020</span> */}
                        <span>
                          {" "}
                          {new Date(
                            this.state.blg_details.created_at
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })}
                        </span>
                      </div>
                      {/* <div className="tag_item ml-2">
                        <i className="icomoon icon-comment"></i>
                        <span>2 Comments</span>
                      </div> */}
                    </div>

                    <Popover
                      tipSize={0.1}
                      body={
                        <>
                          <FacebookShareButton
                            url={window.location.href}
                            quote={this.state.blg_details.title}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <WhatsappShareButton
                            url={window.location.href}
                            title={this.state.blg_details.title}
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <TwitterShareButton
                            url={window.location.href}
                            title={this.state.blg_details.title}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                        </>
                      }
                      isOpen={this.state.isOpen}
                      preferPlace="below"
                      onOuterAction={() =>this.togglePopover()}
                    >
                      <div
                        onClick={() => this.togglePopover()}
                        className="report-tile"
                      >
                        <div className="tag_wrpr_right">
                          share this post<i className="icomoon icon-share"></i>
                        </div>
                      </div>
                    </Popover>
                  </div>

                  <div className="mt-3">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.blg_details.body,
                      }}
                    ></p>
                  </div>
                  {/* <div className="quotes_wrapper">
                    <p>
                      Curabitur non nulla sit amet nisl tempus convallis quis ac
                      lectus. Nulla porttitor accumsan tincidunt. Praesent
                      sapien massa, convallis a pellentesque nec, egestas non
                      nisi. Vivamus suscipit tortor eget felis porttitor
                      volutpat. Vestibulum ac diam sit amet quam vehicula
                      elementum sed sit amet dui. Cras ultricies ligula sed
                      magna dictum porta. Donec rutrum congue leo eget
                      malesuada. Nulla quis lorem ut libero malesuada feugiat.
                      Curabitur non nulla sit amet nisl tempus convallis quis ac
                      lectus. Nulla porttitor accumsan tincidunt. Praesent
                      sapien massa, convallis a pellentesque nec, egestas non
                      nisi. Vivamus suscipit tortor eget felis porttitor
                      volutpat. Vestibulum ac diam sit amet quam vehicula
                      elementum sed sit amet dui. Cras ultricies ligula sed
                      magna dictum porta. Donec rutrum congue leo eget
                      malesuada. Nulla quis lorem ut libero malesuada feugiat.
                      Curabitur non nulla sit amet nisl tempus convallis quis ac
                      lectus. Nulla porttitor accumsan tincidunt. Praesent
                      sapien massa, convallis a pellentesque nec, egestas non
                      nisi. Vivamus suscipit tortor eget felis porttitor
                      volutpat. Vestibulum ac diam sit amet quam vehicula
                      elementum sed sit amet dui. Cras ultricies ligula sed
                      magna dictum porta. Donec rutrum congue leo eget
                      malesuada. Nulla quis lorem ut libero malesuada feugiat.
                    </p>
                  </div>
                  <p>
                    Curabitur non nulla sit amet nisl tempus convallis quis ac
                    lectus. Nulla porttitor accumsan tincidunt. Praesent sapien
                    massa, convallis a pellentesque nec, egestas non nisi.
                    Vivamus suscipit tortor eget felis porttitor volutpat.
                    Vestibulum ac diam sit amet quam vehicula elementum sed sit
                    amet dui. Cras ultricies ligula sed magna dictum porta.
                    Donec rutrum congue leo eget malesuada. Nulla quis lorem ut
                    libero malesuada feugiat.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <Loader></Loader>
    );
  }
}
const mapStateToProps = (state) => ({
  blogs: state.data.blog_dt,
});

export default connect(mapStateToProps, {
  getBlogDetails,
})(BlogDetails);
