import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import { connect } from "react-redux";
import { getCountryList } from "../redux/actions/dataAction";
import Masonryy from "../components/commonCmp/Masonryy";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import NetflixLoader from "../components/Skeleton/ListSkeleton";
import store from "../redux/store";
const images = [
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img1.png",
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img2.png",
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img3.png",
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img4.png",
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img5.png",
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img6.png",
  process.env.PUBLIC_URL + "assets/images/imgs/abroad/img7.png",
];
class StudyAbrod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctryList: null,
    };
  }
  componentDidMount() {
    const { ctry_list } = store.getState().data;
    if (ctry_list) {
      this.setState({
        ctryList: ctry_list,
      });
    } else {
      this.props.getCountryList();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.crty_lst !== this.props.crty_lst) {
      if (nextProps.crty_lst) {
        this.setState({
          ctryList: nextProps.crty_lst,
        });
      }
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    return (
      <>
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper abroad mb-5">
          <div className="container">
            <div className="sec_heading_wrapper">
              <div className="d-flex flex-column align-items-center">
                <p className="pill_head pr_bg_cl ">Study abroad</p>
                {/* <h1 className="main_heabing">Do we offer at the OverseasEye</h1> */}
                <p className="sub_heabing ">
                  Studying Abroad is a dream for many students across the world.
                  covid 19 has grappled most of the countries and it's been an
                  unprecedented challenge. The realm we live in is a place where
                  everybody is caught up in a rat race to reach the heights of
                  being successful. Many think by just graduating abroad may get
                  a job and has accomplished a dream come true. It's not just
                  about graduating what stands out. It's all about where you
                  graduated from and what grades you have attained. We are here
                  to guide you with some of the magnificent universities around
                  the world to show you the path towards a successful career.
                </p>
              </div>
            </div>

            {this.state.ctryList ? (
              <Masonryy
                ctryList={this.state.ctryList}
                history={this.props.history}
              ></Masonryy>
            ) : (
              <div style={{ height: 800 }}>
                <NetflixLoader></NetflixLoader>
              </div>
            )}
          </div>
        </div>
        <Footer></Footer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  crty_lst: state.data.ctry_list,
});
export default connect(mapStateToProps, { getCountryList })(StudyAbrod);
