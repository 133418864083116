import React, { Component } from "react";
import isEmpty from "../../redux/validation/is-Empty";
import { saveEnquiry, getLeadsTypes } from "../../redux/actions/dataAction";
import { connect } from "react-redux";
class SideEnq extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      comment: "",
      phone: "",
      country_id: "",
      courseId: "",
      type: "",
      leadTypes: null,
      loader: false,
      error: {
        nameErr: "",
        emailErr: "",
        phoneErr: "",
        ctryErr: "",
        typeErr: null,
      },
    };
  }
  componentDidMount() {
    // console.log(this.props);
    if (this.props.courseDt) {
      this.setState({
        country_id: this.props.country,
        courseId: this.props.courseId,
      });
    } else {
      if (this.props.general_dt) {
      } else {
        this.checkByID(this.props.slug);
      }
    }
    this.props.getLeadsTypes();
  }
  checkByID(slug) {
    if (this.props.ctryLst) {
      for (let i = 0; i < this.props.ctryLst.length; i++) {
        if (this.props.ctryLst[i].slug === slug) {
          // console.log(this.props.ctryLst[i]);
          this.setState({
            country_id: this.props.ctryLst[i].id,
          });
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.courseDt) {
      this.setState({
        country_id: nextProps.country,
        courseId: nextProps.courseId,
      });
    } else {
      if (nextProps.general_dt) {
      } else {
        this.checkByID(nextProps.slug);
      }
    }
    if (nextProps.saveEq !== this.props.saveEq) {
      if (nextProps.saveEq) {
        if (!isEmpty(nextProps.saveEq)) {
          this.setState({
            loader: false,
            name: "",
            email: "",
            subject: "",
            comment: "",
            phone: "",
            country_id: "",
            courseId: "",
            type: "",
            leadTypes: null,
            error: {
              nameErr: "",
              emailErr: "",
              subjectErr: "",
              phoneErr: "",
            },
          });
        }
      }
    }
    if (nextProps.ldTypes !== this.props.ldTypes) {
      if (nextProps.ldTypes) {
        this.setState({
          leadTypes: nextProps.ldTypes,
        });
      }
    }
  }
  formValidator() {
    let isValid = false;
    let nm = false,
      em = false,
      psw = false,
      phno = false,
      tp = false,
      sub = false;
    let rEmailErr =
      this.state.email.length <= 0
        ? "Email is required!"
        : /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            this.state.email
          )
        ? (em = true)
        : "Email id is not valid!";
    let tpErr = this.state.type.length <= 0 ? "Type is required!" : (tp = true);
    // let rPasswordErr =
    //   this.state.name.length <= 0 ? "Name is required!" : (psw = true);
    // let phnoErr =
    //   this.state.phone.length <= 0 ? "Phone is required!" : (phno = true);
    let ctryErr =
      this.state.country_id.length <= 0 ? "Country is required!" : (sub = true);

    this.setState({
      error: {
        emailErr: rEmailErr,
        // nameErr: rPasswordErr,
        // phoneErr: phnoErr,
        ctryErr: ctryErr,
        typeErr: tpErr,
      },
    });
    if (em && tp) {
      isValid = true;
    }
    return isValid;
  }
  submitForm(e) {
    e.preventDefault();
    // toast.success("Wow so easy!")
    if (this.formValidator()) {
      this.setState({
        loader: true,
      });
      const data = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        comment: this.state.comment,
        phone: this.state.phone,
        course_id: this.state.courseId,
        country_id: this.state.country_id,
        lead_type_id: this.state.type,
        user_id: "",
      };
      this.props.saveEnquiry(data);
    }
  }
  render() {
    // console.log(this.state);
    const { error } = this.state;
    return (
      <div className="side_enq_form">
        <h6 className="heading">Quick Enquiry</h6>
        {/* <p>Lorem ipsum dolor sit amet, consectet urqui sacu.</p> */}
        <form onSubmit={(e) => this.submitForm(e)} noValidate>
          <div class="mb-2">
            <label class="form-label">Name</label>
            <input
              type="text"
              class="form-control enq_inp"
              placeholder=""
              name="name"
              value={this.state.name}
              onChange={(e) =>
                this.setState({
                  name: e.target.value,
                })
              }
            />
            {error.nameErr && <p className="mb-0 error">{error.nameErr}</p>}
          </div>
          <div class="mb-2">
            <label class="form-label">
              Email<span>*</span>
            </label>
            <input
              type="text"
              class="form-control enq_inp"
              placeholder=""
              name="email"
              value={this.state.email}
              onChange={(e) =>
                this.setState({
                  email: e.target.value,
                })
              }
            />
            {error.emailErr && <p className="mb-0 error">{error.emailErr}</p>}
          </div>
          {/* <div class="mb-2">
          <label class="form-label">
            Country Code<span>*</span>
          </label>
          <select class="form-select w-100 enq_inp" aria-label="Default select example">
            <option value="1">+91</option>
            <option value="2">+975</option>
            <option value="3">+1</option>
          </select>
        </div> */}
          <div class="mb-2">
            <label class="form-label">Phone</label>
            <input
              type="text"
              class="form-control enq_inp"
              placeholder=""
              name="phone"
              value={this.state.phone}
              onChange={(e) =>
                this.setState({
                  phone: e.target.value,
                })
              }
            />
            {error.phoneErr && <p className="mb-0 error">{error.phoneErr}</p>}
          </div>
          <div class="mb-2">
            <label class="form-label">Type<span>*</span></label>
            <div class="form-group">
              <select
                class="form-control enq_inp"
                name=""
                id=""
                onChange={(e) =>
                  this.setState({
                    type: e.target.value,
                  })
                }
              >
                {this.state.leadTypes ? (
                  <>
                    <option value="">Select Type</option>
                    {this.state.leadTypes.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </>
                ) : (
                  <option value="">Select Type</option>
                )}
              </select>
              {error.typeErr && <p className="mb-0 error">{error.typeErr}</p>}
            </div>
          </div>
          {this.props.ctryList ? (
            <div class="mb-2">
              <label class="form-label">
                Coutry<span>*</span>
              </label>
              <select
                class="form-select w-100 enq_inp"
                aria-label="Default select example"
              >
                {this.props.ctryList.map((ctry, i) => (
                  <option
                    value={ctry.name}
                    selected={this.props.slug === ctry.slug ? true : false}
                  >
                    {ctry.name}
                  </option>
                ))}
              </select>
              {error.ctryErr && <p className="mb-0 error">{error.ctryErr}</p>}
            </div>
          ) : (
            this.props.ctryLst && (
              <div class="mb-2">
                <label class="form-label">
                  Country<span>*</span>
                </label>
                <select
                  class="form-select w-100 enq_inp"
                  aria-label="Default select example"
                  value={this.state.country_id}
                >
                  {this.props.ctryLst.map((ctry, i) => (
                    <option value={ctry.id}>{ctry.name}</option>
                  ))}
                </select>
              </div>
            )
          )}

          {/* <div class="mb-2">
          <label class="form-label">
            Nearest Office<span>*</span>
          </label>
          <select class="form-select w-100 enq_inp" aria-label="Default select example">
            <option value="1">Kanjirapally</option>
            <option value="2">Kottayam</option>
            <option value="3">Ernakulam</option>
          </select>
        </div>
        <div class="mb-2">
          <label class="form-label">
            Service Looking For<span>*</span>
          </label>
          <select class="form-select w-100 enq_inp" aria-label="Default select example">
            <option value="1">Overseas Education</option>
          </select>
        </div> */}
          {this.state.loader ? (
            <div className="pt-2">
              <button type="button" class="btn btn-primary">
                Sending...
              </button>
            </div>
          ) : (
            <div className="pt-2">
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  saveEq: state.data.saveEnq,
  ldTypes: state.data.leadsTypes,
});
export default connect(mapStateToProps, { saveEnquiry, getLeadsTypes })(
  SideEnq
);
