import React, { Component } from "react";

export default class TestimonialCard extends Component {
  render() {
    return (
      <div className="testimonial_card_wrappe">
        <div className="customer_info_wraper">
          <div className="img_wrapper">
            <img
              src= {this.props.testi.image_url}
            ></img>
          </div>
          <div className="customer_info">
            <span className="name">{this.props.testi.customer_name}</span>
            <span className="designation">{this.props.testi.designation}</span>
          </div>
        </div>
        <div className="msg_wrapper">
          <div className="img_wrapper">
            <img
              src={process.env.PUBLIC_URL + "assets/images/imgs/quotes.png"}
            ></img>
          </div>
          <div>
            <p className="msg"  dangerouslySetInnerHTML={{ __html: this.props.testi.customer_testimonial }}>
            {}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
