import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class BannerSec4 extends Component {
  componentDidMount() {
    // this.logoTween = new TimelineLite({ paused: true })
    // .to(this.textItem1, 1 / 4, {y:-80, ease:Power2.easeOut})
    // .to(this.textItem1, 1 / 2, {y:0, ease:Bounce.easeOut, delay:1 / 4});

    // .set(this.textItem1, {y: 0})
    // .to(this.textItem1, 1 / 4, {y:+20, ease:Power2.easeOut}, "bounceme")
    // .to(this.textItem1, 1 / 2, { y:0, ease:Bounce.easeOut, delay:1 / 4 }, "bounceme");
    // .staggerTo( this.textItem1 , 2, { autoAlpha: 1, y: -20 }, 0.1)
    // .staggerTo( this.textItem1 , 1, { autoAlpha: 1, y: 0 }, 0.1);
    // .to(this.textItem1, 2, { x: 100 })
    // .to(this.textItem1, 1, { x: 0, transformOrigin: "center" });
    // this.logoTween1 = new TimelineLite({ paused: true })
    // .to(this.textItem2, 1 / 4, {y:-80, ease:Power2.easeOut})
    // .to(this.textItem2, 1 / 2, {y:0, ease:Bounce.easeOut, delay:1 / 4});
    // .to(this.textItem2, 2, { y: 100 })
    // .to(this.textItem2, 1, { y: 0, transformOrigin: "center" });
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".cmn_anime1",
        start: "top center",
        // end: "bottom bottom",
        // once:true,
        // scrub: true,
        // markers: true
      },
    });

    tl.fromTo(
      ".anm_item",
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
    tl.fromTo(
      ".banner_bg_color_two",
      {
        opacity: 0,
        width: 0,
        ease: "power1.in",
      },
      {
        opacity: 1,
        width: "76%",
        ease: "power1.out",
      }
    );

    //  tl.to(this.mainHead, 2, { y: 10 })
    //  tl.to(this.mainHead, 1, { y: 0, transformOrigin: "center" });
    // this.logoTween.play();
    // this.logoTween1.play();
    //this.logoTween2.play();
  }
  render() {
    return (
      <div className="position-relative cmn_anime1">
        <div className="banner_bg_color_two"></div>
        <div className="container">
          <div className="content_sec_wrapper">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="img_sec">
                  <div className="dots_img_wrapper">
                    <img
                      src={
                        process.env.PUBLIC_URL + "assets/images/imgs/dots.png"
                      }
                    ></img>
                  </div>
                  <div className="image_wrapper">
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <div
                      className="image_items_info item1"
                      ref={(r) => (this.textItem1 = r)}
                    >
                      <div className="image_wrapper">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/chart.png"
                          }
                        ></img>
                      </div>
                      <div className="image_item_content">
                        <span className="content_sm">Your</span>
                        <span className="content_lg">Career Success</span>
                      </div>
                    </div>
                    <div
                      className="image_items_info item2"
                      ref={(r) => (this.textItem2 = r)}
                    >
                      <div className="image_wrapper">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/lap.png"
                          }
                        ></img>
                      </div>
                      <div className="image_item_content">
                        <span className="content_sm">Your</span>
                        <span className="content_lg">Higher Education</span>
                      </div>
                    </div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/imgs/study_abroad.jpg"
                      }
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content_desc_wrapper">
                  <div className="content_container">
                    <p className="main_head_sm anm_item">Why </p>
                    <h1 className="main_head_lg anm_item">Study Abroad?</h1>
                    <p className="main_head_desc anm_item">
                      We are here to guide you with some of the magnificent
                      universities around the world to show you the path towards
                      a successful career.
                    </p>
                    <div className="mt-3">
                      {/* <button
                        type="button"
                        class="btn btn-primary rounded-pill"
                      >
                        Find out how
                      </button> */}
                      <div class="button-container-2 anm_item">
                        <span class="mas rounded-pill">Read more</span>
                        <button
                          type="button"
                          class="btn btn-primary rounded-pill"
                          onClick={() =>
                            this.props.history.push("/study-abroad")
                          }
                        >
                          Read more
                        </button>
                        {/* this.props.history.push("/service/nurses-abroad") */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
