export default {
  COURSE_LIMIT:10,
  apiGateway: {
    GOOGLE_MAP_KEY: "AIzaSyB4wgZIDNn2Bt7LDLoQYfoN-XjsOdvG_ew",
    GOOGLE_MAP_ZOOM: 8,
    // Staging api urls

    URL: "https://admin.overseaseye.com/api/",
  },
};
