import React, { Component } from "react";
import ServiceCard from "../../components/card/ServiceCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TimelineLite, CSSPlugin } from "gsap/all";

export default class ServicesSec extends Component {
  constructor(props) {
    super(props);
    // cards, elements tha will be used in the tween
    this.cards = [];
    // the timeline instance
    // this.tl = new TimelineLite({ paused: true });
  }
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    // this.tl = new TimelineLite({ paused: true });

    // this.tl.staggerTo( this.cards , 0.5, { autoAlpha: 1, y: -20 }, 0.1);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".service_sec_wrapper",
        start: "top center",
        // end: "bottom bottom",
        // once:true,
        // scrub: true,
        markers: false,
      },
    });
    tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
    // tl.staggerTo(this.cards, 0.5, { autoAlpha: 1, y: -50 }, 0.1);
  }
  render() {
    // this.tl.kill().clear().pause(0);
    return (
      <div className="service_sec_wrapper mt-4 mb-4">
        <div className="container">
          <div className="sec_heading_wrapper">
            <div className="d-flex flex-column align-items-center">
              <p className="pill_head pr_bg_cl" onClick={() => this.tl.play()}>
                What
              </p>
              <h1 className="main_heabing">Do we offer at the OverseasEye?</h1>
              <p className="sub_heabing">
                We guide and help you to achieve your dream come true
              </p>
            </div>
          </div>
          <div className="mt-5">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4" ref={(div) => (this.cards[0] = div)}>
                <ServiceCard
                  img={process.env.PUBLIC_URL + "assets/images/icons/chart.svg"}
                  bg_color={"color1"}
                  heading={"Career Counseling"}
                  desc={
                    "Our well-trained group of counsellors will guide you to the right university and course abroad that completely suits your aptitude, interests, academic and financial background. With our free online or face to face counselling students are given a complete picture down to the finest details regarding the course, the expenses, scholarships, opportunities and the process to be followed for top-rated universities."
                  }
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[1] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[1] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/chats-teardrop-duotone.svg"
                  }
                  bg_color={"color2"}
                  // heading={"Travel and Relocation Assistance"}
                  heading={"Visa Assistance"}
                  desc={
                    "We professionally scrutinize your essential documents for preparation of visa files. We make our students ready for their visa interview through mock sessions for a trouble-free visa processing that ensures high success rates. i.e. right from the application filling to document preparation which meets the most stringent standards required by visa offices of the respective country. "
                  }
                 
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[5] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[5] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/relocation.svg"
                  }
                  bg_color={"color6"}
                  // heading={"Visa Attestation"}
                  heading={"Relocation Assistance"}
                  desc={
                    "We assist students with their travel and relocation for the course of study thereby ensuring a worry-free transition.  We understand the stress and pressure this phase can cause and hence offer a complete and thorough travel and relocation service, which includes anything and everything like the airport pick up to accommodation, bank account creation, getting a SIM card and so on. "
                  }
                  // desc={
                  //   "Document attestation is undertaken to prove the genuineness of a document- to attain a work permit in your target country and more. Depending on the document and the country, the procedures can differ. In general, attestation requires a lot of time, effort and knowledge. There are several procedures to follow, a myriad of formalities to complete and several departments to go through before obtaining the final attested copy. Not to mention the transport to and from the attesting authority. This is why the layman often outsources this process and we should be your number one choice."
                  // }
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[2] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[2] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/medal-duotone.svg"
                  }
                  bg_color={"color3"}
                  heading={"Scholarships"}
                  desc={
                    "We know how much helpful it is to get help with a scholarship while pursuing a course aboard. Scholarships are given strictly based on need and academic qualifications and these vary from country to country."
                  }
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[3] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[3] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/users-duotone.svg"
                  }
                  bg_color={"color4"}
                  heading={"Training & Coaching"}
                  desc={
                    "We offer free IELTS / OET / Interview training for all the candidates who registered with us. We are working with International experts and certified language trainers."
                  }
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[4] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[4] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/book-bookmark-duotone.svg"
                  }
                  bg_color={"color5"}
                  heading={"Education Loan"}
                  desc={
                    "We offer educational loan assistance linked with national and private banks/financiers to meet your university fee and living expenses abroad."
                  }
                ></ServiceCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
