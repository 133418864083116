import React, { Component } from "react";
import EmTypeCard from "../../components/card/EmTypeCard";

export default class TypesSec extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/federal.svg"
              }
              title={"Federal Skillerd Workers"}
            ></EmTypeCard>
          </div>

          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/canada.svg"
              }
              title={"Immigration to Qubec"}
            ></EmTypeCard>
          </div>
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/sin_canada.svg"
              }
              title={"Study in Canada"}
            ></EmTypeCard>
          </div>
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/live_in.svg"
              }
              title={"Live-in Caregiver"}
            ></EmTypeCard>
          </div>
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/business.svg"
              }
              title={"Business Migration"}
            ></EmTypeCard>
          </div>
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/worker.svg"
              }
              title={"Temporary Work"}
            ></EmTypeCard>
          </div>
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/family.svg"
              }
              title={"Family Sponsorship"}
            ></EmTypeCard>
          </div>
          <div className="col-lg-3 mb-4">
            <EmTypeCard
              img={
                process.env.PUBLIC_URL + "assets/images/imgs/types/express.svg"
              }
              title={"Express Visa"}
            ></EmTypeCard>
          </div>
        </div>
      </div>
    );
  }
}
