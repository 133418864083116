import {
  HOME_SLIDER,
  HOME_SLIDER_ERR,
  BLOG_LIST,
  BLOG_LIST_ERR,
  TESTI_LIST,
  TESTI_LIST_ERR,
  CAREER_LIST,
  CAREER_LIST_ERR,
  PARTNER_LIST,
  PARTNER_LIST_ERR,
  COUNTRY_LIST_ERR,
  COUNTRY_LIST,
  COURSE_CAT_LIST,
  COURSE_CAT_LIST_ERR,
  BLOG_DT,
  BLOG_DT_ERR,
  COUNTRY_DETAIL,
  COUNTRY_DETAIL_ERR,
  EDU_LEVEL,
  EDU_LEVEL_ERR,
  GET_COURSES,
  GET_COURSES_ERR,
  GET_COURSES_DT,
  GET_COURSES_DT_ERR,
  GENERAL_DETAILS,
  GENERAL_DETAILS_ERR,
  GET_FAQ_LIST,
  GET_FAQ_LIST_ERR,
  SAVE_ENQ,
  SAVE_ENQ_ERR,
  GET_COURSES_LOAD,
  GET_COURSES_LOAD_ERR,
  CATEGORY_DETAILS,
  CATEGORY_DETAILS_ERR,
  LEAD_TYPES,
  LEAD_TYPES_ERR
} from "../types/types";
import axios from "axios";
import config from "../../Config";
import { toast } from "react-toastify";
import Config from "../../Config";

export const getHomeSlider = () => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-banner-list", {
      // headers: {
      //   Authorization: "Bearer " + localStorage.access_token,
      //   "Access-Control-Allow-Origin": "*",
      // },
    })
    .then((response) => {
      dispatch({
        type: HOME_SLIDER,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: HOME_SLIDER_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getBlogList = (data) => (dispatch) => {
  axios
    .get(
      config.apiGateway.URL +
        "get-blogs-list?page=" +
        data.page +
        "&pagination_limit=" +
        data.pagination_limit,
      data
    )
    .then((response) => {
      // console.log(response);
      dispatch({
        type: BLOG_LIST,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      dispatch({
        type: BLOG_LIST_ERR,
        // status: err.response.status,
        payload: err.response,
      });
    });
};

export const getTestiList = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-testimonial-list")
    .then((response) => {
      dispatch({
        type: TESTI_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: TESTI_LIST_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getCareerList = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-career-streams")
    .then((response) => {
      dispatch({
        type: CAREER_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CAREER_LIST_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getPartners = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-partners")
    .then((response) => {
      // console.log(response);
      dispatch({
        type: PARTNER_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PARTNER_LIST_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getCountryList = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-country-list")
    .then((response) => {
      // console.log(response);
      dispatch({
        type: COUNTRY_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: COUNTRY_LIST_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getCategoryList = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-category-list?country=" + data.country)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: COURSE_CAT_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: COURSE_CAT_LIST_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getBlogDetails = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "blogs/" + data.slug)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: BLOG_DT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: BLOG_DT_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getCtryDetails = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "countries/" + data.slug)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: COUNTRY_DETAIL,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: COUNTRY_DETAIL_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getEduLevel = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-education-level?country=" + data.country)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: EDU_LEVEL,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: EDU_LEVEL_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getCourses = (data) => (dispatch) => {
  axios
    .get(
      config.apiGateway.URL +
        "get-courses?education_level=" +
        data.education_level_id +
        "&category=" +
        data.category +
        "&country=" +
        data.country +
        "&page=1&pagination_limit=" +
        Config.COURSE_LIMIT
    )
    .then((response) => {
      // console.log(response);
      dispatch({
        type: GET_COURSES,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_COURSES_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getCoursesMore = (data) => (dispatch) => {
  axios
    .get(
      config.apiGateway.URL +
        "get-courses?education_level=" +
        data.education_level_id +
        "&category=" +
        data.category +
        "&country=" +
        data.country +
        "&page=1&pagination_limit=" +
        Config.COURSE_LIMIT +
        "&page=" +
        data.page
    )
    .then((response) => {
      // console.log(response);
      dispatch({
        type: GET_COURSES_LOAD,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_COURSES_LOAD_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getCoursesDt = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "courses/" + data.slug)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: GET_COURSES_DT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_COURSES_DT_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getGeneralDetails = (data) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "general_pages/" + data.slug)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: GENERAL_DETAILS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GENERAL_DETAILS_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getFaq = (d) => (dispatch) => {
  axios
    .get(
      config.apiGateway.URL +
        "get-faq-list?page=" +
        d.page +
        "&pagination_limit=" +
        d.pagination_limit
    )
    .then((response) => {
      // console.log(response);
      dispatch({
        type: GET_FAQ_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      dispatch({
        type: GET_FAQ_LIST_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const saveEnquiry = (data) => (dispatch) => {
  axios
    .post(config.apiGateway.URL + "leads", data)
    .then((response) => {
      // console.log(response);
      toast.success("Enquiry submitted successfully ");
      dispatch({
        type: SAVE_ENQ,
        payload: response.data,
      });
    })
    .catch((err) => {
      // console.log(err);

      dispatch({
        type: SAVE_ENQ_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

export const getCategoryDetails = (slug) => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "categories/"+ slug)
    .then((response) => {
      // console.log(response);
      dispatch({
        type: CATEGORY_DETAILS,
        payload: response.data,
      });
    })
    .catch((err) => {
      // console.log(err);

      dispatch({
        type: CATEGORY_DETAILS_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};
export const getLeadsTypes = () => (dispatch) => {
  axios
    .get(config.apiGateway.URL + "get-lead-type-list")
    .then((response) => {
      // console.log(response);
      dispatch({
        type: LEAD_TYPES,
        payload: response.data,
      });
    })
    .catch((err) => {
      // console.log(err);

      dispatch({
        type: LEAD_TYPES_ERR,
        status: err.response.status,
        payload: err.response,
      });
    });
};

