import React, { Component } from "react";
import CourseCard from "../components/card/CourseCard";
import Footer from "../components/commonCmp/Footer";
import SideEnq from "../components/commonCmp/SideEnq";
import TopNav from "../components/commonCmp/TopNav";
import { connect } from "react-redux";
import {
  getCategoryList,
  getCtryDetails,
  getCountryList,
} from "../redux/actions/dataAction";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CourseSec from "../pageCmp/Course/CourseSec";
import queryString from "query-string";
import isEmpty from "../redux/validation/is-Empty";
import Loader from "../components/commonCmp/Loader";

class AbroadInner extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      category: null,
      details: null,
      ctryList: null,
      slug: null,
      slugId: null,
    };

    this.cards = [];
  }

  componentDidMount() {
    // console.log(this.props.match.params.slug);

    this.setState({
      slug: this.props.match.params.slug,
    });
    let params = queryString.parse(this.props.location.search);
    // console.log(params);
    if (!isEmpty(params)) {
      this.setState({
        tab: parseInt(params.type),
      });
    }
    this.props.getCtryDetails({ slug: this.props.match.params.slug });
    this.props.getCountryList();
    this.props.getCategoryList({ country: this.props.match.params.slug });
    gsap.registerPlugin(ScrollTrigger);
    // ScrollTrigger.getAll().forEach(t => t.kill());

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".ttee",
        start: "top center",
        markers: false,
      },
    });
    tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.cat !== this.props.cat) {
      this.setState({
        category: nextProps.cat,
      });
    }
    if (nextProps.abordDt !== this.props.abordDt) {
      if (nextProps.abordDt) {
        this.setState({
          details: nextProps.abordDt,
          slugId: nextProps.abordDt.slug,
        });
      }
    }
    if (nextProps.crty_lst !== this.props.crty_lst) {
      if (nextProps.crty_lst) {
        this.setState({
          ctryList: nextProps.crty_lst,
        });
      }
    }
  }
  changeTab(i) {
    this.setState({
      tab: i,
    });
    // console.log(this.props.match.url);
    this.props.history.push(this.props.match.url + "?type=" + i);
    // this.tl.play()
  }
  render() {
    return this.state.details ? (
      <>
        <TopNav history={this.props}  history={this.props.history} ></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper crse_lst mt-5">
          <div className="top_banner">
            <img src={this.state.details.image_url}></img>
            <div className="heading">
              <div className="container">
                <h1 className="heading_text">{this.state.details.name}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="abroad_info_wrapper pt-5 pb-5">
              <div className="tab_wrapper">
                <div
                  className={`tab_item ${this.state.tab === 0 && "active"}`}
                  onClick={() => this.changeTab(0)}
                >
                  <span>Overview</span>
                </div>
                <div
                  className={`tab_item ${this.state.tab === 1 && "active"}`}
                  onClick={() => this.changeTab(1)}
                >
                  <span>Courses related</span>
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-lg-8 ${this.state.tab === 1 && "col-lg-12"}`}
                >
                  {this.state.tab === 0 ? (
                    <div className="abroad_info mt-4">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.details.description,
                        }}
                      ></p>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <CourseSec
                        category={this.state.category}
                        history={this.props.history}
                        slug={this.state.slugId}
                      ></CourseSec>
                    </div>
                  )}
                </div>
                {this.state.tab === 0 && (
                  <div className="col-lg-4">
                    <SideEnq
                      ctryLst={this.state.ctryList}
                      slug={this.state.slug}
                      general_dt={false}
                    ></SideEnq>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <Loader></Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  cat: state.data.course_list,
  abordDt: state.data.ctry_dt,
  crty_lst: state.data.ctry_list,
});

export default connect(mapStateToProps, {
  getCategoryList,
  getCountryList,
  getCtryDetails,
})(AbroadInner);
