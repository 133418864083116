import React, { Component } from "react";

export default class Mission extends Component {
  render() {
    return (
      <>
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 col-md-6 d">
              <div className="slider_content_wrapper">
                <div className="slider_content">
                  <div className="circle"></div>
                  <p className="main_head_sm1 fw-800">Our</p>
                  <h1 className="main_head_lg1">Mission & Values</h1>
                  <p className="main_head_desc fw-500">
                  We are dedicated to our core values, strictly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-sm-none d-md-block d-none">
              <div className="slider_image_wrapper">
                <p>
                  We understand that each student who wishes to study abroad is
                  looking for reliable guidance and support. We take great pride
                  in being a comprehensive support system for all the prospects
                  from counselling to successful enrollment in top-rated
                  universities abroad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
