import React, { Component } from "react";

export default class ExpressEntry extends Component {
  render() {
    return (
      <div className="container express_etry_wrapper">
        <div className="row">
          <div className="col-lg-6  col-md-6 m-auto">
            <h4 className="fw-800">Express Entry</h4>
            <p>
              Express Entry is an electronic application management system that
              has been in place since January 2015.Skilled workers can apply
              under following express entry categories.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
            <div className="img_wrapper">
              <img
                src={
                  "../" +
                  process.env.PUBLIC_URL +
                  "/assets/images/imgs/diagram2.png"
                }
              ></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
