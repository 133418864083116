import React, { Component } from 'react'
import Footer from '../components/commonCmp/Footer'
import TopNav from '../components/commonCmp/TopNav'
import Diagram from '../pageCmp/emmigration/Diagram'
import ExpressEntry from '../pageCmp/emmigration/ExpressEntry'
import TypesSec from '../pageCmp/emmigration/TypesSec'
import BannerSec3 from '../pageCmp/home/BannerSec3'
import BannerSec5 from '../pageCmp/home/BannerSec5'

export default class Emmigration extends Component {
    render() {
        return (
            <div>
                <TopNav history={this.props}  history={this.props.history} ></TopNav>
                 <BannerSec3></BannerSec3>
                 <TypesSec></TypesSec>
                 <BannerSec5></BannerSec5>

                 <Diagram></Diagram>
                 <ExpressEntry></ExpressEntry>
                 <Footer></Footer>
            </div>
        )
    }
}
