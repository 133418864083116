import React, { Component } from "react";

export default class ScrollManager extends Component {
  constructor() {
    super();
    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }
  onScroll = (element) => {
   
    var body = document.body;
    var html = document.documentElement;
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // alert()
      this.props.onEndReached();
      //show loading spinner and make fetch request to api
   }
    // if (body.scrollHeight+this.props.height - html.scrollTop === html.clientHeight) {
    //   this.props.onEndReached();
    // }
  };
  render() {
    return <>{this.props.children}</>;
  }
}
